@use "src/styles/variables" as *;

.header {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  width: 100%;
  padding: 12px 20px;

  @include bp-1280 {
    padding: 12px 32px;
    justify-content: space-between;
    grid-auto-columns: 1fr auto 1fr;
  }
}

.header__icons {
  display: grid;
  justify-content: start;
}

.header__nav {
  display: none;

  @include bp-1280 {
    display: grid;
    justify-content: end;
  }
}

.social-icon-list {
  align-self: start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px 32px;

  @include bp-768 {
    grid-auto-flow: column;
    grid-template-columns: unset;
  }
}

.social-icon {
  display: block;
  width: 30px;
  height: 28px;
  transition: opacity 0.2s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
}

.main-nav-list {
  display: grid;
  grid-gap: 40px;
  grid-auto-flow: column;
}

.main-nav-list-link {
  font-size: 16px;
  transition: opacity 0.2s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
}
