@use "src/styles/variables" as *;

.video-gallery {
  position: relative;
  background-color: #000;

  &:after {
    position: absolute;
    top: -50px;
    right: 0;
    width: 323px;
    height: 268px;
    background-image: url("../images/glow-right.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include retina-display {
      background-image: url("../images/glow-right@2x.png");
    }

    @include bp-768 {
      content: "";
    }

    @include bp-1680 {
      top: -200px;
      width: 692px;
      height: 574px;
    }
  }

  .swiper-wrapper {
    padding: 20px 0;
  }
}

.videos-slider {
  margin-bottom: 30px;

  @include bp-768 {
    margin-bottom: 100px;
  }

  .swiper-slide:not(.swiper-slide-active) {
    opacity: 0.4;
  }
}

.iframe-ratio {
  position: relative;

  @include bp-768 {
    padding-bottom: 56%;
  }

  iframe {
    width: 100%;
    @include bp-768 {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
