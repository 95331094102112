*,
*::before,
*::after {
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  visibility: hidden;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  visibility: hidden;
}

::-webkit-scrollbar-thumb {
  visibility: hidden;
}

html,
body {
  height: 100%;
}

html {
  min-width: 360px;
  font-size: 16px;
  line-height: 1.2;
  font-family: "Syne", sans-serif;
  color: #fff;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

svg,
img {
  display: block;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
ul,
ol {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus-visible {
    outline: 1px dashed blueviolet;
  }

  &:disabled {
    cursor: default;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

a {
  text-decoration: none;
  color: #fff;

  &:focus-visible {
    outline: 1px dashed blueviolet;
  }
}
