@use "src/styles/variables" as *;

.main-footer-wrap {
  background-color: #000;
}

.main-footer {
  display: grid;
  grid-gap: 50px;

  @include bp-1280 {
    grid-gap: 80px;
  }
}

.footer-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  color: #686868;
}

.footer-link {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  transition: opacity 0.2s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
}

.footer-links-list {
  display: grid;
  grid-gap: 12px;
}

.footer-chapters-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 24px;
  grid-column-gap: 32px;

  @include bp-1280 {
    grid-template-columns: repeat(5, 1fr);
  }

  & > li:last-child {
    grid-column: 1/3;

    @include bp-1280 {
      grid-column: unset;
    }
  }
}

.footer-bottom {
  display: grid;
  place-content: center;
  grid-gap: 50px;
  text-align: center;

  @include bp-1280 {
    grid-auto-flow: column;
    place-content: space-between;
  }
}

.footer-social-icon-list {
  align-self: start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px 32px;

  @include bp-768 {
    grid-auto-flow: column;
    grid-template-columns: unset;
  }
}

.footer-social-icon {
  display: block;
  width: 30px;
  height: 28px;
  transition: opacity 0.2s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
}

.copyright {
  font-size: 16px;
  line-height: 1.2;
}
