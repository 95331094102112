@use "src/styles/variables" as *;

.contact {
  &__logo {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 40px;
  }
}

.form-holder {
  position: relative;
}

.form-success {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-gap: 24px;
  place-content: center;
  text-align: center;
  background-color: #f2f2f2;
  border: 1px solid #e2e2e2;

  &__icon {
    margin: 0 auto;
  }
}

.form {
  display: grid;
  grid-gap: 24px;

  @include bp-1280 {
    grid-template-columns: 1fr 1fr;
  }
}

.form-control {
  display: grid;
  align-content: start;
  grid-gap: 4px;

  &--desktop-full {
    @include bp-1280 {
      grid-column: 1 / 3;
    }
  }
}

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000;
}

.input,
.textarea {
  display: block;
  width: 100%;
  padding: 8px 16px;
  background-color: #f2f2f2;
  border: 1px solid #e2e2e2;
  border-radius: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;

  &::placeholder {
    visibility: visible;
    color: #000;
    font-weight: 500;
  }

  @media (hover: hover) {
    &:hover {
      border-color: #747474;
    }
  }

  &:focus {
    border-color: #000000;
  }

  &--error {
    border-color: #ff0000;
  }
}

.input {
  height: 40px;
}

.textarea {
  resize: vertical;
  max-height: 300px;
  min-height: 140px;
}

.error-msg {
  color: #ff0000;
}

.contact-submit {
  background-color: #000;
  color: #fff;
}

.contact-details {
  margin-top: 60px;
  display: grid;
  grid-gap: 20px;

  @include bp-1280 {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
  }
}

.contact-details__text {
  text-align: center;

  @include bp-1280 {
    grid-column: 1 / 3;
  }
}

.contact-details__phone {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;

  @include bp-1280 {
    grid-column: 1 / 2;
    justify-content: end;
  }
}

.contact-details__email {
  display: flex;
  justify-content: center;

  @include bp-1280 {
    grid-column: 2 / 3;
    justify-content: start;
  }
}

.contact-link {
  display: inline-grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  font-size: 20px;
  font-weight: 900;
  font-family: "Darker Grotesque", sans-serif;
  text-decoration: underline;
  color: #000;

  transition: color 0.2s;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }

  &:active {
    color: #393939;
  }
}
