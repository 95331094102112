$bp-412: 412px;
$bp-768: 768px;
$bp-1280: 1280px;
$bp-1680: 1680px;
$bp-1920: 1920px;

@mixin bp-412 {
  @media screen and (min-width: #{$bp-412}) {
    @content;
  }
}

@mixin bp-768 {
  @media screen and (min-width: #{$bp-768}) {
    @content;
  }
}

@mixin bp-1280 {
  @media screen and (min-width: #{$bp-1280}) {
    @content;
  }
}

@mixin bp-1680 {
  @media screen and (min-width: #{$bp-1680}) {
    @content;
  }
}

@mixin bp-1920 {
  @media screen and (min-width: #{$bp-1920}) {
    @content;
  }
}

@mixin retina-display {
  @media screen and (min-resolution: 2dppx), screen and (min-resolution: 192dpi) {
    @content;
  }
}
