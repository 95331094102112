@use "variables" as *;

.title-site {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;

  @include bp-1280 {
    font-size: 44px;
  }
}

.text-s {
  font-size: 16px;
  line-height: 1.2;

  @include bp-1280 {
    font-size: 24px;
  }
}

.text-m {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;

  @include bp-1280 {
    font-size: 32px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;

  @include bp-1280 {
    font-size: 36px;
  }
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-center {
  text-align: center;
}
