@use "variables" as *;

.button {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  font-weight: 500;
  line-height: 1.5;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 6px 20px rgba(194, 68, 77, 0.2);
    transition: opacity 0.2s;
  }

  @media (hover: hover) {
    &:hover::before {
      opacity: 1;
    }
  }

  &:active {
    &:hover::before {
      opacity: 0;
    }
  }

  &--s {
    height: 48px;
    font-size: 20px;
  }

  &--l {
    height: 68px;
    font-size: 24px;
  }

  &--white {
    background-color: #fff;
    color: #000;

    &::before {
      box-shadow: 0 20px 40px rgba(234, 234, 204, 0.3);
    }
  }

  &--black {
    background-color: #000;
    color: #fff;

    &::before {
      box-shadow: 0 20px 20px rgba(194, 68, 77, 0.2);
    }
  }

  &--gradient {
    color: #000;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 39.58%
      ),
      linear-gradient(
        78.23deg,
        #caa0e7 5.06%,
        #d0d4fc 23.01%,
        #e3e7c5 59.79%,
        #ffdcbc 89.12%
      );

    &::before {
      box-shadow: 0 12px 40px rgba(234, 234, 204, 0.3);
    }
  }

  &__icon {
    width: 30px;
    height: 28px;
  }
}

.button-adaptive {
  width: 100%;

  @include bp-768 {
    max-width: 300px;
    margin: 0 auto;
  }
}

.button-gradient-text {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 39.58%
    ),
    linear-gradient(
      78.23deg,
      #caa0e7 5.06%,
      #d0d4fc 23.01%,
      #e3e7c5 59.79%,
      #ffdcbc 89.12%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
