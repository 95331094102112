@use "base";
@use "typography";
@use "buttons";
@use "common";
@use "sections/header";
@use "sections/main-baner";
@use "sections/about";
@use "sections/statistics";
@use "sections/image-gallery";
@use "sections/video-gallery";
@use "sections/donate";
@use "sections/contact";
@use "sections/footer";
