@use "src/styles/variables" as *;

.statistics-wrap {
  background-color: #000;
}

.statistics {
  overflow: hidden;

  @include bp-1920 {
    padding-left: 280px;
    padding-right: 280px;
  }

  &__body {
    position: relative;
    z-index: 0;
    display: grid;
    grid-gap: 32px;

    &:before {
      position: absolute;
      z-index: -1;
      bottom: -80px;
      left: 50%;
      width: 1440px;
      height: 610px;
      background-image: url("../images/glow-big.png");
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateX(-50%);
      content: "";

      @include retina-display {
        background-image: url("../images/glow-big@2x.png");
      }
    }
  }
}

.statistic-number {
  margin-bottom: 8px;
  font-size: 50px;
  line-height: 1.4;
  font-family: "Darker Grotesque", sans-serif;
  font-weight: 900;
  color: #8ffd74;

  @include bp-1280 {
    font-size: 100px;
  }
}

.statistics-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 32px;
  text-align: center;

  @include bp-768 {
    grid-template-columns: repeat(4, 1fr);
  }
}
