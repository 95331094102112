@use "variables" as *;

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;

  @include bp-768 {
    padding-left: 60px;
    padding-right: 60px;
  }

  @include bp-1280 {
    padding-left: 100px;
    padding-right: 100px;
  }

  @include bp-1680 {
    padding-left: 160px;
    padding-right: 160px;
  }
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;

  @include bp-1280 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.section__title {
  margin-bottom: 32px;

  @include bp-1280 {
    margin-bottom: 52px;
  }
}
